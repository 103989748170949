<template>
  <div class="cashier-desk" v-if="!show">
    <div class="desk-main">
      <div class="pay-info">
        <div class="pay-tip">
          <van-icon name="clock-o" :style="{ marginRight: '0.1rem' }" />等待支付
        </div>
        <div class="pay-money">￥{{ mathManage.formatMoney(payMoney, 2) }}</div>
      </div>
      <div class="pay-type-list">
        <div
          class="type-item"
          v-for="item in payTypeList"
          :key="item.id"
          @click="changePayType(item.id)"
        >
          <div
            class="type-item-sub"
            v-if="
              (item.id == 'wx' && isInWx) ||
              (item.id == 'ali' && isInAli) ||
              (!isInWx && !isInAli)
            "
          >
            <div class="pay-item-info">
              <img :src="item.icon" alt="" />
              <span class="pay-name">{{ item.name }}</span>
            </div>
            <div class="check-box">
              <div
                class="check-item"
                :style="{
                  borderColor: item.checked ? '#ff3a20' : '#ccc',
                  background: item.checked ? '#ff3a20' : '#fff',
                }"
              >
                <van-icon
                  v-if="item.checked"
                  name="success"
                  :style="{ color: '#fff' }"
                />
              </div>
              <!-- <van-checkbox
                class="check-box"
                v-model="item.checked"
                icon-size="0.27rem"
              ></van-checkbox> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desk-footer" v-if="payTypeList.length > 0">
      <div
        class="pay-btn"
        :style="{
          background:
            payTypeList.length > 0
              ? 'linear-gradient(90deg, #ff9577 0%, #ff3a20 100%)'
              : '#ccc',
        }"
        @click="toPay"
      >
        {{ payTypeList.length > 0 ? "去支付" : "该商家没有配置支付方式" }}
      </div>
    </div>
  </div>
  <img v-else class="ffbg-img" src="../assets/images/tip.png" alt="" />
</template>

<script>
import { Icon, Checkbox, Overlay } from "vant";
import {
  ordertopay,
  getWxOpenId,
  getOrderPayMoney,
  getPayTypeList,
} from "@/api/common";
import { mapState } from "vuex";
import url from "url";
import mathManage from "@/utils/mathManage";
import IconSvg from "@/components/IconSvg.vue";
import wx from "weixin-js-sdk";
import "../assets/images/svg/ffbg.svg";

export default {
  name: "CashierDesk",
  components: {
    IconSvg,
    [Icon.name]: Icon,
    [Checkbox.name]: Checkbox,
    [Overlay.name]: Overlay,
  },
  data() {
    return {
      mathManage,
      payTypeList: [],
      payType: "",
      code: "",
      payMoney: "",
      baseUrl: "",
      show: false,
      submitDisabled: false,
    };
  },
  computed: {
    ...mapState(["isInWx", "isInAli", "isMiniProgram", "userInfo"]),
  },

  created() {
    document.title = "收银台";
    const orderId = this.$route.query.id;
    this.orderId = orderId;
    // const code = mathManage.getParam("code");
    const xUrl = url.parse(window.location.href, true);
    const baseUrl = `${xUrl.protocol}//${xUrl.host}`;
    this.baseUrl = baseUrl;

    // this.pushHistory();

    // window.addEventListener(
    //   "popstate",
    //   function (e) {
    //     //alert("我监听到了浏览器的返回按钮事件啦"); //根据自己的需求实现自己的功能
    //     console.log(
    //       '我监听到了浏览器的返回按钮事件啦"); //根据自己的需求实现自己的功能'
    //     );
    //     window.location.replace(`/mall/orderDetails/${orderId}`);
    //   },
    //   false
    // );

    // this.code = code;
    // if (this.isInWx && !this.isMiniProgram && !code) {
    //   const redirect_url = `${baseUrl}/mall/cashierDesk?id=${orderId}`;
    //   const wx_redirect_url = `${configs.redirectUrl}/mall/cashierHandlePage?mall_url=${redirect_url}`;
    //   let wxurl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
    //     configs.appid
    //   }&redirect_uri=${encodeURIComponent(
    //     wx_redirect_url
    //   )}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
    //   window.location.replace(wxurl);
    //   return;
    // }
    this.getOrderPayMoney(orderId);
    this.getPayType();
  },
  mounted() {},
  methods: {
    pushHistory() {
      var state = {
        title: "数字权益商城",
        url: "#",
      };
      window.history.pushState(state, "数字权益商城", "#");
    },
    // 获取支付方式
    async getPayType() {
      const list = [
        {
          id: "wx",
          name: "微信支付",
          checked: false,
          icon: require("../assets/images/wx.png"),
          type: 10,
        },
        {
          id: "ali",
          name: "支付宝",
          checked: false,
          icon: require("../assets/images/ali.png"),
          type: 20,
        },
      ];
      const res = await getPayTypeList();
      if (res && res.code == 0) {
        const data = (res.data.list || []).map((item) => Number(item));
        const newList = list.filter((item) => data.includes(item.type));
        this.payTypeList = newList;
        if (
          this.isInWx &&
          res.data.list &&
          res.data.list.length > 0 &&
          !res.data.list.find((item) => item == "10")
        ) {
          this.show = true;
        }
      } else {
        this.$toast(res.message);
      }
    },
    // 获取订单支付金额
    async getOrderPayMoney(orderId) {
      this.$loading("请稍等...");
      try {
        const res = await getOrderPayMoney({
          orderId,
        });
        this.$toast.clear();
        if (res && res.code == 0) {
          this.payMoney = res.data;
        } else {
          this.$toast(res.message);
        }
      } catch (error) {
        this.$toast.clear();
      }
    },
    // 切换支付方式
    changePayType(id) {
      this.payType = id;
      this.payTypeList = this.payTypeList
        .map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
        .map((item) => {
          if (item.id == id) {
            return {
              ...item,
              checked: true,
            };
          }
          return item;
        });
    },

    // 去支付
    async toPay() {
      if (this.payTypeList.length == 0 || this.submitDisabled) {
        return;
      }
      if (!this.payType) {
        this.$toast("请选择支付方式！");
        return;
      }
      this.submitDisabled = true;
      const payUrl = `${this.baseUrl}/mall/payDetails?id=${this.orderId}`;
      const redirectUrl = `${configs.redirectUrl}/mall/payHandlePage?payUrl=${payUrl}`;
      const quitUrl = `${this.baseUrl}/mall/orderDetails/${this.orderId}`;
      const params = {
        orderId: this.orderId,
        payType: this.payType == "ali" ? 10 : this.isInWx ? 30 : 20,
        returnUrl: redirectUrl,
        quitUrl,
      };
      // 如果是微信小程序内 跳转到微信小程序支付页面
      if (this.isMiniProgram) {
        this.submitDisabled = false;
        wx.miniProgram.navigateTo({
          url: `/pages/pay/pay?orderId=${this.orderId}&userId=${this.userInfo.userId}`,
        });
        return;
      }
      // 如果是微信支付  先获取微信openid
      if (this.isInWx) {
        const openid = this.userInfo?.wxOpenId || "";
        if (!openid) {
          this.$wxLogin();
          return;
        }
        params.openid = openid;
        this.ordertopay(params);
        // this.$loading("请稍等...");
        // try {
        //   const res = await getWxOpenId({
        //     code: this.$route.query.code,
        //   });
        //   this.$toast.clear();
        //   if (res && res.code == 0) {
        //     params.openid = res.data;
        //     this.ordertopay(params);
        //   } else {
        //     this.$toast(res.message);
        //     this.submitDisabled = false;
        //     setTimeout(() => {
        //       this.$router.push("/mall/orderList");
        //     }, 3000);
        //   }
        // } catch (error) {
        //   // this.$toast.clear();
        //   this.submitDisabled = false;
        // }
      } else {
        this.ordertopay(params);
      }
    },

    // 开始支付
    async ordertopay(params) {
      this.$loading("请稍等...");
      try {
        const res = await ordertopay(params);
        this.$toast.clear();
        if (res && res.code == 0) {
          if (this.payType == "wx") {
            if (this.isInWx) {
              this.jsapiPay(res.data);
            } else {
              this.h5Pay(res.data);
            }
          } else {
            this.h5Pay(res.data);
          }
        } else {
          this.$toast(res.message);
          this.submitDisabled = false;
          setTimeout(() => {
            this.$router.push("/mall/orderList");
          }, 3000);
        }
      } catch (error) {
        this.$toast.clear();
        this.submitDisabled = false;
      }
    },

    // 支付宝 h5支付
    h5Pay(data) {
      // 调支付宝/微信收银台
      const url = data;
      this.$toast.clear();
      location.href = url;
    },

    // jsapi 支付  微信环境下
    jsapiPay(data) {
      const result = JSON.parse(data);
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: result.appId, //公众号ID，由商户传入
          timeStamp: result.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: result.nonceStr, //随机串
          package: result.package,
          signType: result.signType, //微信签名方式：
          paySign: result.paySign, //微信签名
        },
        (res) => {
          this.submitDisabled = false;
          // 支付成功
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            // this.$router.push(`/mall/payDetails?id=${this.orderId}`);
            // this.$router.push(`/mall/orderList`);
            // return;
          }
          // 支付过程中用户取消
          if (res.err_msg == "get_brand_wcpay_request:cancel") {
            this.$toast("取消支付！");
            setTimeout(() => {
              this.$router.push(`/mall/orderList`);
            }, 3000);
            return;
          }
          // 支付失败
          if (res.err_msg == "get_brand_wcpay_request:fail") {
            this.$toast("支付失败！");
            return;
          }
          /**
           * 其它
           * 1、请检查预支付会话标识prepay_id是否已失效
           * 2、请求的appid与下单接口的appid是否一致
           * */
          if (res.err_msg == "调用支付JSAPI缺少参数：total_fee") {
            this.$toast("商家参数配置有误！");
            setTimeout(() => {
              this.$router.push(`/mall/orderList`);
            }, 3000);
            return;
          }
          // this.$router.push(`/mall/payDetails?id=${this.orderId}`);
        }
      );
    },
  },
  destroyed() {
    this.$toast.clear();
  },
};
</script>

<style lang='less' scoped>
.ffbg-img {
  width: 100%;
  height: 100%;
}
.cashier-desk {
  height: 100%;
  overflow-y: auto;

  .ffbg-svg {
    width: 100vw;
    height: 100%;
  }

  .desk-main {
    .pay-info {
      height: 2rem;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .pay-tip {
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9b9b9b;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .pay-money {
        font-size: 0.52rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff503f;
        margin-top: 0.26rem;
      }
    }

    .pay-type-list {
      background-color: #fff;
      margin-top: 0.2rem;

      .type-item {
        margin-bottom: 0.2rem;

        .type-item-sub {
          height: 1.2rem;
          display: flex;
          padding: 0 0.4rem;
          justify-content: space-between;
          align-items: center;
        }

        .pay-item-info {
          display: flex;
          align-items: center;

          img {
            width: 0.56rem;
            height: 0.56rem;
          }
          .pay-name {
            font-size: 0.32rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            margin-left: 0.24rem;
          }
        }

        .check-box {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .check-item {
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 50%;
            font-size: 0.24rem;
            color: blue;
            border: 0.02rem solid #d8d8d0;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  .desk-footer {
    width: 100%;
    height: 1rem;
    background: #ffffff;
    padding: 0 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;

    .pay-btn {
      width: 100%;
      height: 0.8rem;
      background: linear-gradient(90deg, #ff9577 0%, #ff3a20 100%);
      border-radius: 0.4rem;
      letter-spacing: 0.02rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.36rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>