var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.show)?_c('div',{staticClass:"cashier-desk"},[_c('div',{staticClass:"desk-main"},[_c('div',{staticClass:"pay-info"},[_c('div',{staticClass:"pay-tip"},[_c('van-icon',{style:({ marginRight: '0.1rem' }),attrs:{"name":"clock-o"}}),_vm._v("等待支付 ")],1),_c('div',{staticClass:"pay-money"},[_vm._v("￥"+_vm._s(_vm.mathManage.formatMoney(_vm.payMoney, 2)))])]),_c('div',{staticClass:"pay-type-list"},_vm._l((_vm.payTypeList),function(item){return _c('div',{key:item.id,staticClass:"type-item",on:{"click":function($event){return _vm.changePayType(item.id)}}},[(
            (item.id == 'wx' && _vm.isInWx) ||
            (item.id == 'ali' && _vm.isInAli) ||
            (!_vm.isInWx && !_vm.isInAli)
          )?_c('div',{staticClass:"type-item-sub"},[_c('div',{staticClass:"pay-item-info"},[_c('img',{attrs:{"src":item.icon,"alt":""}}),_c('span',{staticClass:"pay-name"},[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"check-box"},[_c('div',{staticClass:"check-item",style:({
                borderColor: item.checked ? '#ff3a20' : '#ccc',
                background: item.checked ? '#ff3a20' : '#fff',
              })},[(item.checked)?_c('van-icon',{style:({ color: '#fff' }),attrs:{"name":"success"}}):_vm._e()],1)])]):_vm._e()])}),0)]),(_vm.payTypeList.length > 0)?_c('div',{staticClass:"desk-footer"},[_c('div',{staticClass:"pay-btn",style:({
        background:
          _vm.payTypeList.length > 0
            ? 'linear-gradient(90deg, #ff9577 0%, #ff3a20 100%)'
            : '#ccc',
      }),on:{"click":_vm.toPay}},[_vm._v(" "+_vm._s(_vm.payTypeList.length > 0 ? "去支付" : "该商家没有配置支付方式")+" ")])]):_vm._e()]):_c('img',{staticClass:"ffbg-img",attrs:{"src":require("../assets/images/tip.png"),"alt":""}})}
var staticRenderFns = []

export { render, staticRenderFns }